import React, { useEffect, useState } from 'react';
import CourseCard from './CourseCard/CourseCard';
import './styles/Course.css';


const Courses = () => {
    const [courses, setCourses] = useState([]);
    
    // Veritabanından tüm kursları çekmek için fetch kullan
    useEffect(() => {
        const fetchCourses = async () => {
            try {   
                const response = await fetch(`${process.env.REACT_APP_API_URL_CEBIR_URL}/api/admin/course/cebir/all`);
                const text = await response.text(); // Yanıtı text olarak al
                console.log('Raw Response:', text); // Yanıtı logla
                
                if (response.ok) {
                    const data = JSON.parse(text); // JSON.parse ile parse et
                    console.log('Fetched Courses:', data); // Fetched veriyi kontrol edin
                    setCourses(data);
                } else {
                    console.error('Kurslar alınırken hata oluştu:', text);
                }
            } catch (error) {
                console.error('Hata:', error);
            }
        };

    fetchCourses();
}, []);


    return (
        <div className="courses-container">
            {courses.length > 0 ? (
                courses.map((course, index) => (
                    <CourseCard
                        key={index}
                        title={course.title}
                        date={`${course.startDate} - ${course.endDate}`}
                        time={`${course.startTime} - ${course.endTime}`}
                        students={course.students}
                        info={course.info}
                    />
                ))
            ) : (
                <p>Henüz eklenmiş bir kurs bulunmuyor.</p> // Eğer kurs yoksa mesaj göster
            )}
        </div>
    );
};

export default Courses;
