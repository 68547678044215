import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './components/Home/Home';
import Register from './components/Register/Register';
import Login from './components/Login/Login';
import Dashboard from './components/Dashboard/Dashboard';

const App = () => {
    return (
        <Router>
            <div className="app-container">
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path='/register' element={<Register/>}/>
                    <Route path="/login" element={<Login/>}/> 
                    <Route path='/dashboard' element={<Dashboard/>}/>
                    {/*
                   
                    <Route path='/admin/afdb358b71e8b2f26262b3b206abb5071ed31405d7ee878399f21089769d5f46/login' element={<AdminLogin/>}/>
                    <Route path='/admin/069847f0e69ca29c8ae7ab9adde7e8eb287a5a95cb3e25b2b68de36e0949b0e4/panel' element={<AdminPanel/>}/>
                    <Route path='/admin/adde7e8eb287a5a95cb3e25b2b68de36e0949b0069847f0e69ca29c8ae7ab9e4/add/group' element={<CoursePanel/>}/>
                    <Route path='/admin/b3e25b2b68de36e0949b0069/add/student' element={<StudentManager/>}/> */}                 
                </Routes>
            </div>
        </Router>
    );
};

export default App;
