import React, { useState, useEffect } from 'react';
import './styles/Login.css'; // CSS dosyanızı burada import edin
import { useNavigate } from 'react-router-dom';


const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [notificationMessage, setNotificationMessage] = useState('');
    const [notificationType, setNotificationType] = useState(''); // 'success' veya 'error' olabilir

    const navigate = useNavigate();
   

    // Bildirimin 3 saniye sonra kaybolması için useEffect kullanımı
    useEffect(() => {
        if (notificationMessage) {
            const timer = setTimeout(() => {
                setNotificationMessage('');
                setNotificationType('');
            }, 3000); // 3 saniye sonra bildirim kaybolur

            return () => clearTimeout(timer); // Komponent unmount olursa timer'ı temizler
        }
    }, [notificationMessage]);

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL_CEBIR}/api/users/cebir/login`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ email, password }),
                credentials: 'include'
            });

            if (!response.ok) {
                throw new Error("Login failed");
            }

            const data = await response.json();
            console.log(data.message); // "Login successful"
        

            // Bildirim mesajını ayarla ve yönlendirme işlemini yap
            setNotificationMessage("Giriş başarılı!");
            setNotificationType('success');
            setTimeout(() => {
                navigate("/dashboard");
            }, 2000); // 2 saniye sonra yönlendirme

        } catch (error) {
            console.error("Error:", error);
            
            // Bildirim mesajını ayarla
            setNotificationMessage("Giriş başarısız! Lütfen e-posta ve şifrenizi kontrol edin.");
            setNotificationType('error');
        }
    };

    const handleRegister = () => {
        navigate('/register');
    };

    return (
        <div className="login-container">
            <div className="login-form-container">
                <h1>Giriş Yap</h1>
                <form className="login-form" onSubmit={handleSubmit}>
                    <div className="form-group">    
                        <label htmlFor="email">E-posta:</label>
                        <input 
                            type="email" 
                            id="email" 
                            value={email} 
                            onChange={(e) => setEmail(e.target.value)} 
                            required 
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="password">Şifre:</label>
                        <input 
                            type="password" 
                            id="password" 
                            value={password} 
                            onChange={(e) => setPassword(e.target.value)} 
                            required 
                        />
                    </div>
                    <button type="submit" className="submit-button">Giriş Yap</button>
                </form>
                <button type="button" className='register-nav-button' onClick={handleRegister}>Kayıt ol</button>
                
                {notificationMessage && (
                    <div className={`notification ${notificationType}`}>
                        {notificationMessage}
                    </div>
                )}
            </div>
        </div>
    );
};

export default Login;
