import React, { useState, useEffect } from 'react';
import './styles/register.css';// CSS dosyanızı burada import edin
import { useNavigate } from 'react-router-dom';


const Register = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [name, setName] = useState('');
    const [studentPhone, setstudentPhone] = useState('');
    const [familyPhone, setFamilyPhone] = useState('');
    const [studentClass, setStudentClass] = useState('');
    const [studentTime, setStudentTime] = useState('');
    const [selectedCity, setSelectedCity] = useState('');
    const [selectedDistrict, setSelectedDistrict] = useState('');
    const [cityOptions, setCityOptions] = useState([]);
    const [districtOptions, setDistrictOptions] = useState([]);
    const [searchCity, setSearchCity] = useState('');
    const [searchDistrict, setSearchDistrict] = useState('');
    const [birthdate, setBirthdate] = useState([]);
    const [familyFullName, setfamilyFullName] = useState('');
    const [family2FullName, setfamily2FullName] = useState('');
    const [notificationMessage, setNotificationMessage] = useState('');
    const [studentSection, setStudentSection] = useState('');
    const [notificationType, setNotificationType] = useState('');
    const navigate = useNavigate();


    // Örnek şehir ve ilçe verileri
    const cities = [
        { "name": "Adana" },
        { "name": "Adıyaman" },
        { "name": "Afyonkarahisar" },
        { "name": "Ağrı" },
        { "name": "Aksaray" },
        { "name": "Amasya" },
        { "name": "Ankara" },
        { "name": "Antalya" },
        { "name": "Ardahan" },
        { "name": "Artvin" },
        { "name": "Aydın" },
        { "name": "Balıkesir" },
        { "name": "Bartın" },
        { "name": "Batman" },
        { "name": "Bayburt" },
        { "name": "Bilecik" },
        { "name": "Bingöl" },
        { "name": "Bitlis" },
        { "name": "Bolu" },
        { "name": "Burdur" },
        { "name": "Bursa" },
        { "name": "Çanakkale" },
        { "name": "Çankırı" },
        { "name": "Çorum" },
        { "name": "Denizli" },
        { "name": "Diyarbakır" },
        { "name": "Düzce" },
        { "name": "Edirne" },
        { "name": "Elazığ" },
        { "name": "Erzincan" },
        { "name": "Erzurum" },
        { "name": "Eskişehir" },
        { "name": "Gaziantep" },
        { "name": "Giresun" },
        { "name": "Gümüşhane" },
        { "name": "Hakkari" },
        { "name": "Hatay" },
        { "name": "Iğdır" },
        { "name": "Isparta" },
        { "name": "İstanbul" },
        { "name": "İzmir" },
        { "name": "Kahramanmaraş" },
        { "name": "Karabük" },
        { "name": "Karaman" },
        { "name": "Kars" },
        { "name": "Kastamonu" },
        { "name": "Kayseri" },
        { "name": "Kilis" },
        { "name": "Kırıkkale" },
        { "name": "Kırklareli" },
        { "name": "Kırşehir" },
        { name: 'Kocaeli' },
        { name: 'Kütahya' },
        { name: 'Malatya' },
        { name: 'Manisa' },
        { name: 'Mardin' },
        { name: 'Mersin' },
        { name: 'Muğla' },
        { name: 'Muş' },
        { name: 'Nevşehir' },
        { name: 'Niğde' },
        { name: 'Ordu' },
        { name: 'Osmaniye' },
        { name: 'Rize' },
        { name: 'Sakarya' },
        { name: 'Samsun' },
        { name: 'Şanlıurfa' },
        { name: 'Siirt' },
        { name: 'Sinop' },
        { name: 'Şırnak' },
        { name: 'Sivas' },
        { name: 'Tekirdağ' },
        { name: 'Tokat' },
        { name: 'Trabzon' },
        { name: 'Tunceli' },
        { name: 'Uşak' },
        { name: 'Van' },
        { name: 'Yalova' },
        { name: 'Yozgat' },
        { name: 'Zonguldak' }
    ];

    const districts = {
        'Adana': [
            { "name": "Adana Merkez" },
            { "name": "Seyhan" },
            { "name": "Yüreğir" },
            { "name": "Ceyhan" },
            { "name": "Kozan" },
            { "name": "Feke" },
            { "name": "İmamoğlu" },
            { "name": "Karaisalı" },
            { "name": "Karataş" },
            { "name": "Saimbeyli" },
            { "name": "Tufanbeyli" },
            { "name": "Çukurova" },
            { "name": "Sarıçam" },
            { "name": "Pozantı" },
            { "name": "Yumurtalık" }
        ],
        'Adıyaman': [
            { "name": "Besni" },
            { "name": "Çelikhan" },
            { "name": "Gerger" },
            { "name": "Gölbaşı" },
            { "name": "Kahta" },
            { "name": "Merkez" },
            { "name": "Samsat" },
            { "name": "Sincik" },
            { "name": "Tut" }
        ],
        'Afyonkarahisar': [
            { "name": "Başmakçı" },
            { "name": "Bayat" },
            { "name": "Bolvadin" },
            { "name": "Çay" },
            { "name": "Çobanlar" },
            { "name": "Dazkırı" },
            { "name": "Dinar" },
            { "name": "Emirdağ" },
            { "name": "Evciler" },
            { "name": "Hocalar" },
            { "name": "İhsaniye" },
            { "name": "İscehisar" },
            { "name": "Kızılören" },
            { "name": "Merkez" },
            { "name": "Sandıklı" },
            { "name": "Sinanpaşa" },
            { "name": "Sultandağı" },
            { "name": "Şuhut" }
        ],
        'Ağrı': [
            { "name": "Diyadin" },
            { "name": "Doğubayazıt" },
            { "name": "Eleşkirt" },
            { "name": "Hamur" },
            { "name": "Merkez" },
            { "name": "Patnos" },
            { "name": "Taşlıçay" },
            { "name": "Tutak" }
        ],
        'Aksaray': [
            { "name": "Ağaçören" },
            { "name": "Eskil" },
            { "name": "Gülağaç" },
            { "name": "Güzelyurt" },
            { "name": "Merkez" },
            { "name": "Ortaköy" },
            { "name": "Sarıyahşi" }
        ],
        'Amasya': [
            { "name": "Göynücek" },
            { "name": "Gümüşhacıköy" },
            { "name": "Hamamözü" },
            { "name": "Merkez" },
            { "name": "Merzifon" },
            { "name": "Suluova" },
            { "name": "Taşova" }
        ],
        'Ankara': [
            { "name": "Altındağ" },
            { "name": "Ayaş" },
            { "name": "Bala" },
            { "name": "Beypazarı" },
            { "name": "Çamlıdere" },
            { "name": "Çankaya" },
            { "name": "Çubuk" },
            { "name": "Elmadağ" },
            { "name": "Güdül" },
            { "name": "Haymana" },
            { "name": "Kalecik" },
            { "name": "Kızılcahamam" },
            { "name": "Nallıhan" },
            { "name": "Polatlı" },
            { "name": "Şereflikoçhisar" },
            { "name": "Yenimahalle" },
            { "name": "Gölbaşı" },
            { "name": "Keçiören" },
            { "name": "Mamak" },
            { "name": "Sincan" },
            { "name": "Kazan" },
            { "name": "Akyurt" },
            { "name": "Etimesgut" },
            { "name": "Evren" },
            { "name": "Pursaklar" }
        ],

        "Antalya": [
            { "name": "Akseki" },
            { "name": "Alanya" },
            { "name": "Elmalı" },
            { "name": "Finike" },
            { "name": "Gazipaşa" },
            { "name": "Gündoğmuş" },
            { "name": "Kaş" },
            { "name": "Korkuteli" },
            { "name": "Kumluca" },
            { "name": "Manavgat" },
            { "name": "Serik" },
            { "name": "Demre" },
            { "name": "İbradı" },
            { "name": "Kemer" },
            { "name": "Aksu" },
            { "name": "Döşemealtı" },
            { "name": "Kepez" },
            { "name": "Konyaaltı" },
            { "name": "Muratpaşa" }
        ],
        "Ardahan": [
            { "name": "Merkez" },
            { "name": "Çıldır" },
            { "name": "Göle" },
            { "name": "Hanak" },
            { "name": "Posof" },
            { "name": "Damal" }
        ],
        "Artvin": [
            { "name": "Ardanuç" },
            { "name": "Arhavi" },
            { "name": "Merkez" },
            { "name": "Borçka" },
            { "name": "Hopa" },
            { "name": "Şavşat" },
            { "name": "Yusufeli" },
            { "name": "Murgul" }
        ],
        "Aydın": [
            { "name": "Merkez" },
            { "name": "Bozdoğan" },
            { "name": "Efeler" },
            { "name": "Çine" },
            { "name": "Germencik" },
            { "name": "Karacasu" },
            { "name": "Koçarlı" },
            { "name": "Kuşadası" },
            { "name": "Kuyucak" },
            { "name": "Nazilli" },
            { "name": "Söke" },
            { "name": "Sultanhisar" },
            { "name": "Yenipazar" },
            { "name": "Buharkent" },
            { "name": "İncirliova" },
            { "name": "Karpuzlu" },
            { "name": "Köşk" },
            { "name": "Didim" }
        ],
        "Balıkesir": [
            { "name": "Altıeylül" },
            { "name": "Ayvalık" },
            { "name": "Merkez" },
            { "name": "Balya" },
            { "name": "Bandırma" },
            { "name": "Bigadiç" },
            { "name": "Burhaniye" },
            { "name": "Dursunbey" },
            { "name": "Edremit" },
            { "name": "Erdek" },
            { "name": "Gönen" },
            { "name": "Havran" },
            { "name": "İvrindi" },
            { "name": "Karesi" },
            { "name": "Kepsut" },
            { "name": "Manyas" },
            { "name": "Savaştepe" },
            { "name": "Sındırgı" },
            { "name": "Gömeç" },
            { "name": "Susurluk" },
            { "name": "Marmara" }
        ],
        "Bartın": [
            { "name": "Merkez" },
            { "name": "Kurucaşile" },
            { "name": "Ulus" },
            { "name": "Amasra" }
        ],
        "Batman": [
            { "name": "Merkez" },
            { "name": "Beşiri" },
            { "name": "Gercüş" },
            { "name": "Kozluk" },
            { "name": "Sason" },
            { "name": "Hasankeyf" }
        ],
        "Bayburt": [
            { "name": "Merkez" },
            { "name": "Aydıntepe" },
            { "name": "Demirözü" }
        ],
        "Bilecik": [
            { "name": "Merkez" },
            { "name": "Bozüyük" },
            { "name": "Gölpazarı" },
            { "name": "Osmaneli" },
            { "name": "Pazaryeri" },
            { "name": "Söğüt" },
            { "name": "Yenipazar" },
            { "name": "İnhisar" }
        ],

        "Bingöl": [
            { "name": "Merkez" },
            { "name": "Genç" },
            { "name": "Karlıova" },
            { "name": "Kiğı" },
            { "name": "Solhan" },
            { "name": "Adaklı" },
            { "name": "Yayladere" },
            { "name": "Yedisu" }
        ],

        "Bitlis": [
            { "name": "Adilcevaz" },
            { "name": "Ahlat" },
            { "name": "Merkez" },
            { "name": "Hizan" },
            { "name": "Mutki" },
            { "name": "Tatvan" },
            { "name": "Güroymak" }
        ],

        "Bolu": [
            { "name": "Merkez" },
            { "name": "Gerede" },
            { "name": "Göynük" },
            { "name": "Kıbrıscık" },
            { "name": "Mengen" },
            { "name": "Mudurnu" },
            { "name": "Seben" },
            { "name": "Dörtdivan" },
            { "name": "Yeniçağa" }
        ],

        "Burdur": [
            { "name": "Ağlasun" },
            { "name": "Bucak" },
            { "name": "Merkez" },
            { "name": "Gölhisar" },
            { "name": "Tefenni" },
            { "name": "Yeşilova" },
            { "name": "Karamanlı" },
            { "name": "Kemer" },
            { "name": "Altınyayla" },
            { "name": "Çavdır" },
            { "name": "Çeltikçi" }
        ],

        "Bursa": [
            { "name": "Gemlik" },
            { "name": "İnegöl" },
            { "name": "İznik" },
            { "name": "Karacabey" },
            { "name": "Keles" },
            { "name": "Mudanya" },
            { "name": "Mustafakemalpaşa" },
            { "name": "Orhaneli" },
            { "name": "Orhangazi" },
            { "name": "Yenişehir" },
            { "name": "Büyükorhan" },
            { "name": "Harmancık" },
            { "name": "Nilüfer" },
            { "name": "Osmangazi" },
            { "name": "Yıldırım" },
            { "name": "Gürsu" },
            { "name": "Kestel" }
        ],

        "Çanakkale": [
            { "name": "Ayvacık" },
            { "name": "Bayramiç" },
            { "name": "Biga" },
            { "name": "Bozcaada" },
            { "name": "Çan" },
            { "name": "Merkez" },
            { "name": "Eceabat" },
            { "name": "Ezine" },
            { "name": "Gelibolu" },
            { "name": "Gökçeada" },
            { "name": "Lapseki" },
            { "name": "Yenice" }
        ],

        "Çankırı": [
            { "name": "Merkez" },
            { "name": "Çerkeş" },
            { "name": "Eldivan" },
            { "name": "Ilgaz" },
            { "name": "Kurşunlu" },
            { "name": "Orta" },
            { "name": "Şabanözü" },
            { "name": "Yapraklı" },
            { "name": "Atkaracalar" },
            { "name": "Kızılırmak" },
            { "name": "Bayramören" },
            { "name": "Korgun" }
        ],
        "Çorum": [
            { "name": "Alaca" },
            { "name": "Bayat" },
            { "name": "Merkez" },
            { "name": "İskilip" },
            { "name": "Kargı" },
            { "name": "Mecitözü" },
            { "name": "Ortaköy" },
            { "name": "Osmancık" },
            { "name": "Sungurlu" },
            { "name": "Boğazkale" },
            { "name": "Uğurludağ" },
            { "name": "Dodurga" },
            { "name": "Laçin" },
            { "name": "Oğuzlar" }
        ],

        "Denizli": [
            { "name": "Acıpayam" },
            { "name": "Buldan" },
            { "name": "Çal" },
            { "name": "Çameli" },
            { "name": "Çardak" },
            { "name": "Çivril" },
            { "name": "Merkez" },
            { "name": "Merkezefendi" },
            { "name": "Pamukkale" },
            { "name": "Güney" },
            { "name": "Kale" },
            { "name": "Sarayköy" },
            { "name": "Tavas" },
            { "name": "Babadağ" },
            { "name": "Bekilli" },
            { "name": "Honaz" },
            { "name": "Serinhisar" },
            { "name": "Baklan" },
            { "name": "Beyağaç" },
            { "name": "Bozkurt" }
        ],

        "Diyarbakır": [
            { "name": "Kocaköy" },
            { "name": "Çermik" },
            { "name": "Çınar" },
            { "name": "Çüngüş" },
            { "name": "Dicle" },
            { "name": "Ergani" },
            { "name": "Hani" },
            { "name": "Hazro" },
            { "name": "Kulp" },
            { "name": "Lice" },
            { "name": "Silvan" },
            { "name": "Eğil" },
            { "name": "Bağlar" },
            { "name": "Kayapınar" },
            { "name": "Sur" },
            { "name": "Yenişehir" },
            { "name": "Bismil" }
        ],
        "Düzce": [
            { "name": "Akçakoca" },
            { "name": "Merkez" },
            { "name": "Yığılca" },
            { "name": "Cumayeri" },
            { "name": "Gölyaka" },
            { "name": "Çilimli" },
            { "name": "Gümüşova" },
            { "name": "Kaynaşlı" }
        ],
        "Edirne": [
            { "name": "Merkez" },
            { "name": "Enez" },
            { "name": "Havsa" },
            { "name": "İpsala" },
            { "name": "Keşan" },
            { "name": "Lalapaşa" },
            { "name": "Meriç" },
            { "name": "Uzunköprü" },
            { "name": "Süloğlu" }
        ],

        "Elazığ": [
            { "name": "Ağın" },
            { "name": "Baskil" },
            { "name": "Merkez" },
            { "name": "Karakoçan" },
            { "name": "Keban" },
            { "name": "Maden" },
            { "name": "Palu" },
            { "name": "Sivrice" },
            { "name": "Arıcak" },
            { "name": "Kovancılar" },
            { "name": "Alacakaya" }
        ],

        "Erzincan": [
            { "name": "Çayırlı" },
            { "name": "Merkez" },
            { "name": "İliç" },
            { "name": "Kemah" },
            { "name": "Kemaliye" },
            { "name": "Refahiye" },
            { "name": "Tercan" },
            { "name": "Üzümlü" },
            { "name": "Otlukbeli" }
        ],

        "Erzurum": [
            { "name": "Aşkale" },
            { "name": "Çat" },
            { "name": "Hınıs" },
            { "name": "Horasan" },
            { "name": "İspir" },
            { "name": "Karayazı" },
            { "name": "Narman" },
            { "name": "Oltu" },
            { "name": "Olur" },
            { "name": "Pasinler" },
            { "name": "Şenkaya" },
            { "name": "Tekman" },
            { "name": "Tortum" },
            { "name": "Karaçoban" },
            { "name": "Uzundere" },
            { "name": "Pazaryolu" },
            { "name": "Köprüköy" },
            { "name": "Palandöken" },
            { "name": "Yakutiye" },
            { "name": "Aziziye" }
        ],

        "Eskişehir": [
            { "name": "Çifteler" },
            { "name": "Mahmudiye" },
            { "name": "Mihalıççık" },
            { "name": "Sarıcakaya" },
            { "name": "Seyitgazi" },
            { "name": "Sivrihisar" },
            { "name": "Alpu" },
            { "name": "Beylikova" },
            { "name": "İnönü" },
            { "name": "Günyüzü" },
            { "name": "Han" },
            { "name": "Mihalgazi" },
            { "name": "Odunpazarı" },
            { "name": "Tepebaşı" }
        ],
        "Gaziantep": [
            { "name": "Araban" },
            { "name": "İslahiye" },
            { "name": "Nizip" },
            { "name": "Oğuzeli" },
            { "name": "Yavuzeli" },
            { "name": "Şahinbey" },
            { "name": "Şehitkamil" },
            { "name": "Karkamış" },
            { "name": "Nurdağı" }
        ],

        "Giresun": [
            { "name": "Alucra" },
            { "name": "Bulancak" },
            { "name": "Dereli" },
            { "name": "Espiye" },
            { "name": "Eynesil" },
            { "name": "Merkez" },
            { "name": "Görele" },
            { "name": "Keşap" },
            { "name": "Şebinkarahisar" },
            { "name": "Tirebolu" },
            { "name": "Piraziz" },
            { "name": "Yağlıdere" },
            { "name": "Çamoluk" },
            { "name": "Çanakçı" },
            { "name": "Doğankent" },
            { "name": "Güce" }
        ],

        "Gümüşhane": [
            { "name": "Merkez" },
            { "name": "Kelkit" },
            { "name": "Şiran" },
            { "name": "Torul" },
            { "name": "Köse" },
            { "name": "Kürtün" }
        ],

        "Hakkari": [
            { "name": "Çukurca" },
            { "name": "Merkez" },
            { "name": "Şemdinli" },
            { "name": "Yüksekova" }
        ],

        "Hatay": [
            { "name": "Altınözü" },
            { "name": "Arsuz" },
            { "name": "Defne" },
            { "name": "Dörtyol" },
            { "name": "Hassa" },
            { "name": "Antakya" },
            { "name": "İskenderun" },
            { "name": "Kırıkhan" },
            { "name": "Payas" },
            { "name": "Reyhanlı" },
            { "name": "Samandağ" },
            { "name": "Yayladağı" },
            { "name": "Erzin" },
            { "name": "Belen" },
            { "name": "Kumlu" }
        ],

        "Iğdır": [
            { "name": "Aralık" },
            { "name": "Merkez" },
            { "name": "Tuzluca" },
            { "name": "Karakoyunlu" }
        ],

        "Isparta": [
            { "name": "Atabey" },
            { "name": "Eğirdir" },
            { "name": "Gelendost" },
            { "name": "Merkez" },
            { "name": "Keçiborlu" },
            { "name": "Senirkent" },
            { "name": "Sütçüler" },
            { "name": "Şarkikaraağaç" },
            { "name": "Uluborlu" },
            { "name": "Yalvaç" },
            { "name": "Aksu" },
            { "name": "Gönen" },
            { "name": "Yenişarbademli" }
        ],

        "İstanbul": [
            { "name": "Adalar" },
            { "name": "Bakırköy" },
            { "name": "Beşiktaş" },
            { "name": "Beykoz" },
            { "name": "Beyoğlu" },
            { "name": "Çatalca" },
            { "name": "Eyüp" },
            { "name": "Fatih" },
            { "name": "Gaziosmanpaşa" },
            { "name": "Kadıköy" },
            { "name": "Kartal" },
            { "name": "Sarıyer" },
            { "name": "Silivri" },
            { "name": "Şile" },
            { "name": "Şişli" },
            { "name": "Üsküdar" },
            { "name": "Zeytinburnu" },
            { "name": "Büyükçekmece" },
            { "name": "Kağıthane" },
            { "name": "Küçükçekmece" },
            { "name": "Pendik" },
            { "name": "Ümraniye" },
            { "name": "Bayrampaşa" },
            { "name": "Avcılar" },
            { "name": "Bağcılar" },
            { "name": "Bahçelievler" },
            { "name": "Güngören" },
            { "name": "Maltepe" },
            { "name": "Sultanbeyli" },
            { "name": "Tuzla" },
            { "name": "Esenler" },
            { "name": "Arnavutköy" },
            { "name": "Ataşehir" },
            { "name": "Başakşehir" },
            { "name": "Beylikdüzü" },
            { "name": "Çekmeköy" },
            { "name": "Esenyurt" },
            { "name": "Sancaktepe" },
            { "name": "Sultangazi" }
        ],

        "İzmir": [
            { "name": "Aliağa" },
            { "name": "Bayındır" },
            { "name": "Bergama" },
            { "name": "Bornova" },
            { "name": "Çeşme" },
            { "name": "Dikili" },
            { "name": "Foça" },
            { "name": "Karaburun" },
            { "name": "Karşıyaka" },
            { "name": "Kemalpaşa" },
            { "name": "Kınık" },
            { "name": "Kiraz" },
            { "name": "Menemen" },
            { "name": "Ödemiş" },
            { "name": "Seferihisar" },
            { "name": "Selçuk" },
            { "name": "Tire" },
            { "name": "Torbalı" },
            { "name": "Urla" },
            { "name": "Beydağ" },
            { "name": "Buca" },
            { "name": "Konak" },
            { "name": "Menderes" },
            { "name": "Balçova" },
            { "name": "Çiğli" },
            { "name": "Gaziemir" },
            { "name": "Narlıdere" },
            { "name": "Güzelbahçe" },
            { "name": "Bayraklı" },
            { "name": "Karabağlar" }
        ],

        "Kahramanmaraş": [
            { "name": "Afşin" },
            { "name": "Andırın" },
            { "name": "Dulkadiroğlu" },
            { "name": "Onikişubat" },
            { "name": "Elbistan" },
            { "name": "Göksun" },
            { "name": "Merkez" },
            { "name": "Pazarcık" },
            { "name": "Türkoğlu" },
            { "name": "Çağlayancerit" },
            { "name": "Ekinözü" },
            { "name": "Nurhak" }
        ],

        "Karabük": [
            { "name": "Eflani" },
            { "name": "Eskipazar" },
            { "name": "Merkez" },
            { "name": "Ovacık" },
            { "name": "Safranbolu" },
            { "name": "Yenice" }
        ],


        "Karaman": [
            { "name": "Ermenek" },
            { "name": "Merkez" },
            { "name": "Ayrancı" },
            { "name": "Kazımkarabekir" },
            { "name": "Başyayla" },
            { "name": "Sarıveliler" }
        ],

        "Kars": [
            { "name": "Arpaçay" },
            { "name": "Digor" },
            { "name": "Kağızman" },
            { "name": "Merkez" },
            { "name": "Sarıkamış" },
            { "name": "Selim" },
            { "name": "Susuz" },
            { "name": "Akyaka" }
        ],

        "Kastamonu": [
            { "name": "Abana" },
            { "name": "Araç" },
            { "name": "Azdavay" },
            { "name": "Bozkurt" },
            { "name": "Cide" },
            { "name": "Çatalzeytin" },
            { "name": "Daday" },
            { "name": "Devrekani" },
            { "name": "İnebolu" },
            { "name": "Merkez" },
            { "name": "Küre" },
            { "name": "Taşköprü" },
            { "name": "Tosya" },
            { "name": "İhsangazi" },
            { "name": "Pınarbaşı" },
            { "name": "Şenpazar" },
            { "name": "Ağlı" },
            { "name": "Doğanyurt" },
            { "name": "Hanönü" },
            { "name": "Seydiler" }
        ],

        "Kayseri": [
            { "name": "Bünyan" },
            { "name": "Develi" },
            { "name": "Felahiye" },
            { "name": "İncesu" },
            { "name": "Pınarbaşı" },
            { "name": "Sarıoğlan" },
            { "name": "Sarız" },
            { "name": "Tomarza" },
            { "name": "Yahyalı" },
            { "name": "Yeşilhisar" },
            { "name": "Akkışla" },
            { "name": "Talas" },
            { "name": "Kocasinan" },
            { "name": "Melikgazi" },
            { "name": "Hacılar" },
            { "name": "Özvatan" }
        ],

        "Kilis": [
            { "name": "Merkez" },
            { "name": "Elbeyli" },
            { "name": "Musabeyli" },
            { "name": "Polateli" }
        ],

        "Kırıkkale": [
            { "name": "Delice" },
            { "name": "Keskin" },
            { "name": "Merkez" },
            { "name": "Sulakyurt" },
            { "name": "Bahşili" },
            { "name": "Balışeyh" },
            { "name": "Çelebi" },
            { "name": "Karakeçili" },
            { "name": "Yahşihan" }
        ],


        "Kırklareli": [
            { "name": "Babaeski" },
            { "name": "Demirköy" },
            { "name": "Merkez" },
            { "name": "Kofçaz" },
            { "name": "Lüleburgaz" },
            { "name": "Pehlivanköy" },
            { "name": "Pınarhisar" },
            { "name": "Vize" }
        ],

        "Kırşehir": [
            { "name": "Çiçekdağı" },
            { "name": "Kaman" },
            { "name": "Merkez" },
            { "name": "Mucur" },
            { "name": "Akpınar" },
            { "name": "Akçakent" },
            { "name": "Boztepe" }
        ],

        "Kocaeli": [
            { "name": "Gebze" },
            { "name": "Gölcük" },
            { "name": "Kandıra" },
            { "name": "Karamürsel" },
            { "name": "Körfez" },
            { "name": "Derince" },
            { "name": "Başiskele" },
            { "name": "Çayırova" },
            { "name": "Darıca" },
            { "name": "Dilovası" },
            { "name": "İzmit" },
            { "name": "Kartepe" }
        ],

        "Konya": [
            { "name": "Akşehir" },
            { "name": "Beyşehir" },
            { "name": "Bozkır" },
            { "name": "Cihanbeyli" },
            { "name": "Çumra" },
            { "name": "Doğanhisar" },
            { "name": "Ereğli" },
            { "name": "Hadim" },
            { "name": "Ilgın" },
            { "name": "Kadınhanı" },
            { "name": "Karapınar" },
            { "name": "Kulu" },
            { "name": "Sarayönü" },
            { "name": "Seydişehir" },
            { "name": "Yunak" },
            { "name": "Akören" },
            { "name": "Altınekin" },
            { "name": "Derebucak" },
            { "name": "Hüyük" },
            { "name": "Karatay" },
            { "name": "Meram" },
            { "name": "Selçuklu" },
            { "name": "Taşkent" },
            { "name": "Ahırlı" },
            { "name": "Çeltik" },
            { "name": "Derbent" },
            { "name": "Emirgazi" },
            { "name": "Güneysınır" },
            { "name": "Halkapınar" },
            { "name": "Tuzlukçu" },
            { "name": "Yalıhüyük" }
        ],

        "Kütahya": [
            { "name": "Altıntaş" },
            { "name": "Domaniç" },
            { "name": "Emet" },
            { "name": "Gediz" },
            { "name": "Merkez" },
            { "name": "Simav" },
            { "name": "Tavşanlı" },
            { "name": "Aslanapa" },
            { "name": "Dumlupınar" },
            { "name": "Hisarcık" },
            { "name": "Şaphane" },
            { "name": "Çavdarhisar" },
            { "name": "Pazarlar" }
        ],

        "Malatya": [
            { "name": "Akçadağ" },
            { "name": "Arapgir" },
            { "name": "Arguvan" },
            { "name": "Darende" },
            { "name": "Doğanşehir" },
            { "name": "Hekimhan" },
            { "name": "Merkez" },
            { "name": "Pütürge" },
            { "name": "Yeşilyurt" },
            { "name": "Battalgazi" },
            { "name": "Doğanyol" },
            { "name": "Kale" },
            { "name": "Kuluncak" },
            { "name": "Yazıhan" }
        ],

        "Manisa": [
            { "name": "Akhisar" },
            { "name": "Alaşehir" },
            { "name": "Demirci" },
            { "name": "Gördes" },
            { "name": "Kırkağaç" },
            { "name": "Kula" },
            { "name": "Merkez" },
            { "name": "Salihli" },
            { "name": "Sarıgöl" },
            { "name": "Saruhanlı" },
            { "name": "Selendi" },
            { "name": "Soma" },
            { "name": "Şehzadeler" },
            { "name": "Yunusemre" },
            { "name": "Turgutlu" },
            { "name": "Ahmetli" },
            { "name": "Gölmarmara" },
            { "name": "Köprübaşı" }
        ],
        "Mardin": [
            { "name": "Derik" },
            { "name": "Kızıltepe" },
            { "name": "Artuklu" },
            { "name": "Merkez" },
            { "name": "Mazıdağı" },
            { "name": "Midyat" },
            { "name": "Nusaybin" },
            { "name": "Ömerli" },
            { "name": "Savur" },
            { "name": "Dargeçit" },
            { "name": "Yeşilli" }
        ],
        "Mersin": [
            { "name": "Anamur" },
            { "name": "Erdemli" },
            { "name": "Gülnar" },
            { "name": "Mut" },
            { "name": "Silifke" },
            { "name": "Tarsus" },
            { "name": "Aydıncık" },
            { "name": "Bozyazı" },
            { "name": "Çamlıyayla" },
            { "name": "Akdeniz" },
            { "name": "Mezitli" },
            { "name": "Toroslar" },
            { "name": "Yenişehir" }
        ],
        "Muğla": [
            { "name": "Bodrum" },
            { "name": "Datça" },
            { "name": "Fethiye" },
            { "name": "Köyceğiz" },
            { "name": "Marmaris" },
            { "name": "Menteşe" },
            { "name": "Milas" },
            { "name": "Ula" },
            { "name": "Yatağan" },
            { "name": "Dalaman" },
            { "name": "Seydikemer" },
            { "name": "Ortaca" },
            { "name": "Kavaklıdere" }
        ],

        "Muş": [
            { "name": "Bulanık" },
            { "name": "Malazgirt" },
            { "name": "Merkez" },
            { "name": "Varto" },
            { "name": "Hasköy" },
            { "name": "Korkut" }
        ],

        "Nevşehir": [
            { "name": "Avanos" },
            { "name": "Derinkuyu" },
            { "name": "Gülşehir" },
            { "name": "Hacıbektaş" },
            { "name": "Kozaklı" },
            { "name": "Merkez" },
            { "name": "Ürgüp" },
            { "name": "Acıgöl" }
        ],

        "Niğde": [
            { "name": "Bor" },
            { "name": "Çamardı" },
            { "name": "Merkez" },
            { "name": "Ulukışla" },
            { "name": "Altunhisar" },
            { "name": "Çiftlik" }
        ],
        "Ordu": [
            { "name": "Akkuş" },
            { "name": "Altınordu" },
            { "name": "Aybastı" },
            { "name": "Fatsa" },
            { "name": "Gölköy" },
            { "name": "Korgan" },
            { "name": "Kumru" },
            { "name": "Mesudiye" },
            { "name": "Perşembe" },
            { "name": "Ulubey" },
            { "name": "Ünye" },
            { "name": "Gülyalı" },
            { "name": "Gürgentepe" },
            { "name": "Çamaş" },
            { "name": "Çatalpınar" },
            { "name": "Çaybaşı" },
            { "name": "İkizce" },
            { "name": "Kabadüz" },
            { "name": "Kabataş" }
        ],

        "Osmaniye": [
            { "name": "Bahçe" },
            { "name": "Kadirli" },
            { "name": "Merkez" },
            { "name": "Düziçi" },
            { "name": "Hasanbeyli" },
            { "name": "Sumbas" },
            { "name": "Toprakkale" }
        ],

        "Rize": [
            { "name": "Ardeşen" },
            { "name": "Çamlıhemşin" },
            { "name": "Çayeli" },
            { "name": "Fındıklı" },
            { "name": "İkizdere" },
            { "name": "Kalkandere" },
            { "name": "Pazar" },
            { "name": "Merkez" },
            { "name": "Güneysu" },
            { "name": "Derepazarı" },
            { "name": "Hemşin" },
            { "name": "İyidere" }
        ],

        "Sakarya": [
            { "name": "Akyazı" },
            { "name": "Geyve" },
            { "name": "Hendek" },
            { "name": "Karasu" },
            { "name": "Kaynarca" },
            { "name": "Sapanca" },
            { "name": "Kocaali" },
            { "name": "Pamukova" },
            { "name": "Taraklı" },
            { "name": "Ferizli" },
            { "name": "Karapürçek" },
            { "name": "Söğütlü" },
            { "name": "Adapazarı" },
            { "name": "Arifiye" },
            { "name": "Erenler" },
            { "name": "Serdivan" }
        ],

        "Samsun": [
            { "name": "Alaçam" },
            { "name": "Bafra" },
            { "name": "Çarşamba" },
            { "name": "Havza" },
            { "name": "Kavak" },
            { "name": "Ladik" },
            { "name": "Terme" },
            { "name": "Vezirköprü" },
            { "name": "Asarcık" },
            { "name": "Ondokuzmayıs" },
            { "name": "Salıpazarı" },
            { "name": "Tekkeköy" },
            { "name": "Ayvacık" },
            { "name": "Yakakent" },
            { "name": "Atakum" },
            { "name": "Canik" },
            { "name": "İlkadım" }
        ],
        "Şanlıurfa": [
            { "name": "Akçakale" },
            { "name": "Birecik" },
            { "name": "Bozova" },
            { "name": "Ceylanpınar" },
            { "name": "Eyyübiye" },
            { "name": "Halfeti" },
            { "name": "Haliliye" },
            { "name": "Hilvan" },
            { "name": "Karaköprü" },
            { "name": "Siverek" },
            { "name": "Suruç" },
            { "name": "Viranşehir" },
            { "name": "Harran" }
        ],

        "Siirt": [
            { "name": "Baykan" },
            { "name": "Eruh" },
            { "name": "Kurtalan" },
            { "name": "Pervari" },
            { "name": "Merkez" },
            { "name": "Şirvan" },
            { "name": "Tillo" }
        ],

        "Sinop": [
            { "name": "Ayancık" },
            { "name": "Boyabat" },
            { "name": "Durağan" },
            { "name": "Erfelek" },
            { "name": "Gerze" },
            { "name": "Merkez" },
            { "name": "Türkeli" },
            { "name": "Dikmen" },
            { "name": "Saraydüzü" }
        ],

        "Şirnak": [
            { "name": "Beytüşşebap" },
            { "name": "Cizre" },
            { "name": "İdil" },
            { "name": "Silopi" },
            { "name": "Merkez" },
            { "name": "Uludere" },
            { "name": "Güçlükonak" }
        ],

        "Sivas": [
            { "name": "Divriği" },
            { "name": "Gemerek" },
            { "name": "Gürün" },
            { "name": "Hafik" },
            { "name": "İmranlı" },
            { "name": "Kangal" },
            { "name": "Koyulhisar" },
            { "name": "Merkez" },
            { "name": "Suşehri" },
            { "name": "Şarkışla" },
            { "name": "Yıldızeli" },
            { "name": "Zara" },
            { "name": "Akıncılar" },
            { "name": "Altınyayla" },
            { "name": "Doğanşar" },
            { "name": "Gölova" },
            { "name": "Ulaş" }
        ],

        "Tekirdağ": [
            { "name": "Çerkezköy" },
            { "name": "Çorlu" },
            { "name": "Ergene" },
            { "name": "Hayrabolu" },
            { "name": "Malkara" },
            { "name": "Muratlı" },
            { "name": "Saray" },
            { "name": "Süleymanpaşa" },
            { "name": "Kapaklı" },
            { "name": "Şarköy" },
            { "name": "Marmaraereğlisi" }
        ],

        "Tokat": [
            { "name": "Almus" },
            { "name": "Artova" },
            { "name": "Erbaa" },
            { "name": "Niksar" },
            { "name": "Reşadiye" },
            { "name": "Merkez" },
            { "name": "Turhal" },
            { "name": "Zile" },
            { "name": "Pazar" },
            { "name": "Yeşilyurt" },
            { "name": "Başçiftlik" },
            { "name": "Sulusaray" }
        ],
        "Trabzon": [
            { "name": "Akçaabat" },
            { "name": "Araklı" },
            { "name": "Arsin" },
            { "name": "Çaykara" },
            { "name": "Maçka" },
            { "name": "Of" },
            { "name": "Ortahisar" },
            { "name": "Sürmene" },
            { "name": "Tonya" },
            { "name": "Vakfıkebir" },
            { "name": "Yomra" },
            { "name": "Beşikdüzü" },
            { "name": "Şalpazarı" },
            { "name": "Çarşıbaşı" },
            { "name": "Dernekpazarı" },
            { "name": "Düzköy" },
            { "name": "Hayrat" },
            { "name": "Köprübaşı" }
        ],

        "Tunceli": [
            { "name": "Çemişgezek" },
            { "name": "Hozat" },
            { "name": "Mazgirt" },
            { "name": "Nazımiye" },
            { "name": "Ovacık" },
            { "name": "Pertek" },
            { "name": "Pülümür" },
            { "name": "Merkez" }
        ],

        "Uşak": [
            { "name": "Banaz" },
            { "name": "Eşme" },
            { "name": "Karahallı" },
            { "name": "Sivaslı" },
            { "name": "Ulubey" },
            { "name": "Merkez" }
        ],

        "Van": [
            { "name": "Başkale" },
            { "name": "Çatak" },
            { "name": "Erciş" },
            { "name": "Gevaş" },
            { "name": "Gürpınar" },
            { "name": "İpekyolu" },
            { "name": "Muradiye" },
            { "name": "Özalp" },
            { "name": "Tuşba" },
            { "name": "Bahçesaray" },
            { "name": "Çaldıran" },
            { "name": "Edremit" },
            { "name": "Saray" }
        ],


        "Yalova": [
            { "name": "Merkez" },
            { "name": "Altınova" },
            { "name": "Armutlu" },
            { "name": "Çınarcık" },
            { "name": "Çiftlikköy" },
            { "name": "Termal" }
        ],

        "Yozgat": [
            { "name": "Akdağmadeni" },
            { "name": "Boğazlıyan" },
            { "name": "Çayıralan" },
            { "name": "Çekerek" },
            { "name": "Sarıkaya" },
            { "name": "Sorgun" },
            { "name": "Şefaatli" },
            { "name": "Yerköy" },
            { "name": "Merkez" },
            { "name": "Aydıncık" },
            { "name": "Çandır" },
            { "name": "Kadışehri" },
            { "name": "Saraykent" },
            { "name": "Yenifakılı" }
        ],

        "Zonguldak": [
            { "name": "Çaycuma" },
            { "name": "Devrek" },
            { "name": "Ereğli" },
            { "name": "Merkez" },
            { "name": "Alaplı" },
            { "name": "Gökçebey" }
        ],

    };

    useEffect(() => {
        // Şehirleri filtrele
        setCityOptions(cities.filter(city => city.name.toLowerCase().includes(searchCity.toLowerCase())));
    }, [searchCity]);

    useEffect(() => {
        // İlçeleri filtrele
        if (selectedCity) {
            setDistrictOptions(districts[selectedCity].filter(district => district.name.toLowerCase().includes(searchDistrict.toLowerCase())));
        } else {
            setDistrictOptions([]);
        }
    }, [selectedCity, searchDistrict]);



    const handleSubmit = (e) => {
        e.preventDefault();

        const userData = {
            fullName: name,
            familyFullName: familyFullName,
            family2FullName: family2FullName,
            familyPhone: familyPhone,
            studentPhone: studentPhone,
            studentClass: studentClass,
            studentCities: selectedCity,
            studentDistrict: selectedDistrict,
            studentShift: studentTime,
            email: email,
            studentBirthDate: birthdate,
            password: password
        };




        fetch(`${process.env.REACT_APP_API_URL_CEBIR}/api/users/cebir/create`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(userData)
        })
            .then(response => {
                if (response.status === 202) {
                    // 2 saniye sonra yönlendirme yapılıyor
                    setTimeout(() => {
                        navigate('/login');
                    }, 2000);
                }
                return response.json();
            })
            .then(data => {
                setNotificationMessage('Kayıt başarıyla tamamlandı!');  // Başarı mesajı
                setNotificationType('success');
                navigate('/login')
            })
            .catch((error) => {
                setNotificationMessage('Kayıt sırasında hata oluştu bilgilerinizi kontrol edin');  // Error message
                setNotificationType('error');
            });
    };

    return (
        <div className="register-container">
            <div className="register-form-container">
                <h1>Kayıt Ol</h1>
                {/* Notification message */}
                {notificationMessage && (
                    <div className={`notification ${notificationType}`}>
                        {notificationMessage}
                    </div>
                )}


                <form className="register-form" onSubmit={handleSubmit}>
                    <div className="form-group">
                        <input
                            type="text"
                            id="name"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            placeholder="Öğrenci Adı Soyadı"
                            required
                        />
                    </div>

                    <div className="form-group">
                        <input
                            type="text"
                            id="familyFullName"
                            value={familyFullName}
                            onChange={(e) => setfamilyFullName(e.target.value)}
                            placeholder="Veli Adı Soyadı"
                            required
                        />
                    </div>

                    <div className="form-group">
                        <input
                            type="text"
                            id="family2FullName"
                            value={family2FullName}
                            onChange={(e) => setfamily2FullName(e.target.value)}
                            placeholder="İkinci Veli Adı Soyadı (İsteğe Bağlı)"
                        />
                    </div>

                    <div className="form-group">
                        <input
                            type="number"
                            id="second-guardian-name"
                            value={familyPhone}
                            onChange={(e) => setFamilyPhone(e.target.value)}
                            placeholder="Veli Telefon Numarası"
                            required
                        />
                    </div>

                    <div className="form-group">
                        <input
                            type="number"
                            id="phone"
                            value={studentPhone}
                            onChange={(e) => setstudentPhone(e.target.value)}
                            placeholder="Öğrenci tel"
                        />
                    </div>

                    <div className="form-group">
                        <label htmlFor="student-class"></label>
                        <select
                            id="student-class"
                            value={studentClass}
                            onChange={(e) => setStudentClass(e.target.value)}
                            required
                        >
                            <option value="studentclass" disabled>Öğrenci sınıfı seçiniz</option>
                            <option value="1.sınıf">1.sınıf</option>
                            <option value="2. sınıf">2.sınıf</option>
                            <option value="3. sınıf">3.sınıf</option>
                            <option value="4. sınıf">4.sınıf</option>
                            <option value="5. sınıf">5.sınıf</option>
                            <option value="6. sınıf">6.sınıf</option>
                            <option value="7. sınıf">7.sınıf</option>
                            <option value="8. sınıf">8.sınıf</option>
                            <option value="9. sınıf">9.sınıf</option>
                            <option value="10. sınıf">10.sınıf</option>
                            <option value="11. sınıf">11.sınıf</option>
                            <option value="12. sınıf">12.sınıf</option>
                            <option value="Mezun öğrenci">Mezun</option>
                            <option value="Üniversite öğrencisi">Üniversite öğrencisi</option>
                            <option value="Yetişkin">Yetişkin</option>
                        </select>
                    </div>

                    <section className="location-section">
                        <div className="form-group">
                            <input
                                type="text"
                                id="city-search"
                                value={searchCity}
                                onChange={(e) => setSearchCity(e.target.value)}
                                placeholder="İl arayın"
                                className="search-bar"
                            />
                            <select
                                id="city-select"
                                value={selectedCity}
                                onChange={(e) => setSelectedCity(e.target.value)}
                                required
                            >
                                <option value="" disabled>İl seçiniz</option>
                                {cityOptions.map(city => (
                                    <option key={city.id} value={city.id}>{city.name}</option>
                                ))}
                            </select>
                        </div>

                        <div className="form-group">
                            <input
                                type="text"
                                id="district-search"
                                value={searchDistrict}
                                onChange={(e) => setSearchDistrict(e.target.value)}
                                placeholder="İlçe arayın"
                                className="search-bar"
                            />
                            <select
                                id="district-select"
                                value={selectedDistrict}
                                onChange={(e) => setSelectedDistrict(e.target.value)}
                                required
                            >
                                <option value="" disabled>İlçe seçiniz</option>
                                {districtOptions.map(district => (
                                    <option key={district.id} value={district.id}>{district.name}</option>
                                ))}
                            </select>
                        </div>
                    </section>

                    <div className="form-group">
                        <label htmlFor="student-time"></label>
                        <select
                            id="student-time"
                            value={studentTime}
                            onChange={(e) => setStudentTime(e.target.value)}
                            required
                        >
                            <option value="" disabled selected>Öğrenci okul saatleri</option>
                            <option value="Sabah">Sabahcı</option>
                            <option value="Öğlen">Öğlenci</option>
                            <option value="Akşam">Tam Gün</option>
                            <option value="Diğer">Diğer</option>
                            /</select>
                    </div>

                    <div className='form-group'>
                        <label htmlFor='student-section'></label>
                        <select
                            id="student-section"
                            value={studentSection}
                            onChange={(e) => setStudentSection(e.target.value)}
                            
                        >
                            <option value="" disabled selected>Öğrencinin Aldığı Diğer Kurslar</option>
                            <option value="Anka">Anka Hızlı Okuma</option>
                            /</select>
                    </div>

                    <div className="form-group">
                        <input
                            type="email"
                            id="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            placeholder="E-posta"
                            required
                        />
                    </div>

                    <div className="form-group">
                        <input
                            type="date"
                            id="birthdate"
                            value={birthdate}
                            onChange={(e) => setBirthdate(e.target.value)}
                            placeholder="Öğrenci Doğum Tarihi"
                            required
                        />
                    </div>

                    <div className="form-group">
                        <input
                            type="password"
                            id="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            placeholder="Şifre"
                            required
                        />
                    </div>

                    <button type="submit" className="submit-button">Kayıt Ol</button>
                </form>
            </div>
        </div>
    );
};

export default Register;
